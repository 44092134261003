import React, { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import { useForm } from "react-hook-form"
import Button from "react-bootstrap/Button"
// import useRazorpay from "react-razorpay"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { navigate } from "gatsby"
import AwardDeadlineBanner from "../components/AwardDeadlineBanner"
import derection from "../pdfs/ESGActivitiesPoliciesDescriptionandExamples.pdf"
const initialFormState = {
  achievements: [
    { type: "Environmental" },
    { type: "Social" },
    { type: "Governance" },
  ],
  other_details: [{ type: "" }],
}

export default function Nominate() {
  const {
    register,
    handleSubmit,
    formState: { data = {}, errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialFormState,
  })
  const [formArrayFieldsCount, setFormArrayFieldsCount] = useState({
    achievements: initialFormState.achievements.length,
    other_details: initialFormState.other_details.length,
  })

  function removeHandle(data) {
    if (data === "otherDetails") {
      setFormArrayFieldsCount({
        ...formArrayFieldsCount,
        other_details: formArrayFieldsCount.other_details - 1,
      })
    } else if (data === "achievements") {
      setFormArrayFieldsCount({
        ...formArrayFieldsCount,
        achievements: formArrayFieldsCount.achievements - 1,
      })
    }
  }

  const onSubmit = async formData => {
    await Promise.all([
      ...formData.achievements.map((achievement, index) => {
        let { attachment } = achievement

        if (attachment?.length === 0 || attachment === null) {
          return (attachment = null)
        }
        const fileForm = new FormData()
        fileForm.append("files", attachment[0])

        return (
          axios
            // .post("http://localhost:1337/api/upload", fileForm)
            .post("https://api.csr.skillsscale.in/api/upload", fileForm)
            .then(({ data }) => {
              formData.achievements[index].attachment = data[0].id
            })
        )
      }),
      ...formData.other_details.map((other_detail, index) => {
        let { attachment } = other_detail

        if (attachment?.length === 0 || attachment === null) {
          return (attachment = null)
        }
        const fileForm = new FormData()
        fileForm.append("files", attachment[0])
        return (
          axios
            // .post("http://localhost:1337/api/upload", fileForm)
            .post("https://api.csr.skillsscale.in/api/upload", fileForm)
            .then(({ data }) => {
              formData.other_details[index].attachment = data[0].id
            })
        )
      }),
    ])

    try {
      const payload = {
        data: {
          entity: {
            type: "event-nomination",
            eventId: 1,
          },
          operation: "insert",
          coupon_code: formData.coupon_code,
          data: formData,
        },
      }

      const result = await axios
        // .post(
        //   "http://localhost:1337/api/event-nominations",
        //   payload
        // )
        .post("https://api.csr.skillsscale.in/api/event-nominations", payload)

      if (result.status === 200) {
        navigate("/nominationComplete")
      }
    } catch (error) {
      console.log("error==", error)
      toast.error("Something gone wrong with upload API", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  const nomination_finish =
    new Date().getTime() > new Date("2023-03-13T12:00:00+05:30").getTime()

  return (
    <>
      {!nomination_finish ? (
        <div>
          <div className="container pt-5">
            <h2 className="text-center pb-3">Award Nomination Form</h2>
            <strong>Step 1: </strong>Select Award Category
            <br />
            <strong>Step 2: </strong>Fill the nominatiom form
            <br />
            <strong>Step 3: </strong>Submit case study or white paper in any
            format and any size stating your key achievements along with the
            form. The case study or white paper will be forwarded to the Jury
            for selection. The stronger the Case Study, better the chance of
            winning!! Award Results will be declared one week prior to the event
            & selected winners will be informed accordingly.
            <AwardDeadlineBanner />
          </div>
          <ToastContainer />
          <div className="row mt-4 pt-4 ">
            <div className="col-md-2"></div>
            <div className="col-8">
              <div className="card shadow-lg">
                <div className="card-body">
                  <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <h4 className="mb-3">A. Basic Organisation Details</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Field>
                            <label>Company Name</label>
                            <input
                              className="form-control"
                              placeholder="Enter Company Name"
                              type="text"
                              {...register("company_name", {
                                required: true,
                                minLength: 3,
                              })}
                            />
                            {errors.company_name && (
                              <p className="text-danger">
                                Please check your company name
                              </p>
                            )}
                          </Form.Field>
                        </div>
                        <div className="col-md-6">
                          <Form.Field>
                            <label>Select Your Company Category</label>
                            <select
                              className="form-control"
                              {...register("company_category", {
                                required: true,
                              })}
                            >
                              <option value="">Choose an option</option>
                              <option value="Large Corporate">
                                Large Corporate
                              </option>
                              <option value="MSME">
                                MSME - Micro, Small and Medium Enterprises
                              </option>
                            </select>
                            {errors.company_category && (
                              <p className="text-danger">
                                Please check your Company Category
                              </p>
                            )}
                          </Form.Field>
                        </div>
                        <div className="col-md-6">
                          <Form.Field>
                            <label className="mt-4">
                              Contact Persons Full Name
                            </label>
                            <input
                              placeholder="Contact Persons Full Name"
                              className="form-control"
                              type="text"
                              {...register("full_name", {
                                required: true,
                                minLength: 3,
                              })}
                            />
                            {errors.full_name && (
                              <p className="text-danger">
                                PLease check first Name
                              </p>
                            )}
                          </Form.Field>
                        </div>
                        <div className="col-md-6">
                          <Form.Field>
                            <label className="mt-4">Designation</label>
                            <input
                              placeholder="Enter Designation"
                              className="form-control"
                              type="text"
                              {...register("designation", {
                                required: true,
                                minLength: 3,
                              })}
                            />
                            {errors.designation && (
                              <p className="text-danger">
                                Please check your Designation
                              </p>
                            )}
                          </Form.Field>
                        </div>
                        <div className="col-md-6">
                          <Form.Field>
                            <label className="mt-4">Phone Number</label>
                            <input
                              className="form-control"
                              placeholder="Enter Phone Number"
                              type="number"
                              {...register("phone_number", {
                                required: true,
                                pattern: /^\d{10}$/,
                              })}
                            />
                            {errors.phone_number && (
                              <p className="text-danger">
                                Please check your phone number
                              </p>
                            )}
                          </Form.Field>
                        </div>
                        <div className="col-md-6">
                          <Form.Field>
                            <label className="mt-4">Email</label>
                            <input
                              className="form-control"
                              placeholder="Email"
                              type="email"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            />
                            {errors.email && (
                              <p className="text-danger">
                                Please check your Email address
                              </p>
                            )}
                          </Form.Field>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <h4 className="mb-3">B. Awards Nomination Details</h4>
                        <div className="col-md-6">
                          <div className="text-danger mb-4">
                            <strong>Note: </strong>Files size should be less
                            than 5mb
                          </div>
                          Please refer to the following guiding document to
                          submit your nomination for the Indian ESG Network
                          Awards 2023.
                          <br />
                          <a href={derection} download>
                            Click here
                          </a>
                          <br />
                          <br />
                          <Form.Field>
                            <label>Select Award Category</label>
                            <br />
                            <select
                              className="form-control"
                              {...register("category", {
                                required: true,
                              })}
                            >
                              <option value="">Choose an option</option>
                              <option value="best_esg_practice_LIC">
                                1. Best ESG Practice - Large Indian Corporate /
                                MNCs
                              </option>
                              <option value="best_esg_practice_SMI">
                                2. Best ESG Practice - Indian Small & Medium
                                Enterprises
                              </option>
                              <option value="ESG_organization">
                                3. ESG Enabler of the Year (Organization)
                              </option>
                            </select>
                          </Form.Field>
                          {errors.category && (
                            <p className="text-danger">
                              Please select a cetegory
                            </p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <Form.Field>
                            <label className="mt-4">
                              Top 5 Achievements in the ESG Space (& Supporting
                              Documents, if any)
                            </label>
                            {new Array(formArrayFieldsCount.achievements)
                              .fill("")
                              .map((_, index) => (
                                <div key={index} className="row">
                                  <div className="col-md-2 mt-2">
                                    <input
                                      placeholder="Document Type"
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `achievements[${index}].type`
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-4 mt-2">
                                    <textarea
                                      rows="1"
                                      placeholder="Document Description"
                                      className="form-control"
                                      {...register(
                                        `achievements[${index}].description`
                                      )}
                                    ></textarea>
                                  </div>
                                  <div className="col-md-4 mt-2">
                                    <input
                                      type="file"
                                      accept="application/pdf, image/png"
                                      className="form-control"
                                      {...register(
                                        `achievements[${index}].attachment`
                                      )}
                                    />
                                  </div>
                                  {formArrayFieldsCount.achievements > 1 && (
                                    <div className="col-md-2 mt-3">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() =>
                                          removeHandle("achievements")
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ))}
                            <div className="col-md-4">
                              <button
                                className="btn btn-success mt-3"
                                type="button"
                                onClick={() => {
                                  setValue(
                                    `achievements[${
                                      getValues().achievements.length || 0
                                    }]`,
                                    {
                                      type: "",
                                      description: "",
                                      attachment: null,
                                    }
                                  )
                                  setFormArrayFieldsCount({
                                    ...formArrayFieldsCount,
                                    achievements:
                                      formArrayFieldsCount.achievements + 1,
                                  })
                                }}
                              >
                                Add More
                              </button>
                            </div>
                          </Form.Field>
                        </div>
                        <div className="col-md-12">
                          <label className="mt-5 mb-3">
                            Any other details you would like to share or
                            documents you would like to share <br />(
                            <i style={{ fontSize: "15px" }}>
                              Any other relevant work, relevant certifications/
                              audits/ ratings, letter from Principal or Business
                              Partner etc.
                            </i>
                            ):
                          </label>
                          {new Array(formArrayFieldsCount.other_details)
                            .fill("")
                            .map((_, index) => (
                              <div className="row">
                                <div className="col-md-5 mt-3">
                                  <textarea
                                    placeholder="Document Description"
                                    className="form-control"
                                    {...register(
                                      `other_details[${index}].description`
                                    )}
                                    rows="1"
                                  ></textarea>
                                </div>
                                <div className="col-md-5 mt-3">
                                  <Form.Field>
                                    <input
                                      type="file"
                                      accept="application/pdf, image/png"
                                      className="form-control"
                                      name="attachmentData"
                                      {...register(
                                        `other_details[${index}].attachment`
                                      )}
                                    />
                                  </Form.Field>
                                </div>
                                {formArrayFieldsCount.other_details > 1 && (
                                  <div className="col-md-2 mt-3">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        removeHandle("otherDetails")
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                          <div className="col-md-12">
                            <button
                              className="btn btn-success mt-3"
                              type="button"
                              onClick={() => {
                                setValue(
                                  `other_details[${
                                    getValues().other_details.length || 0
                                  }]`,
                                  {
                                    description: "",
                                    attachment: null,
                                  }
                                )
                                setFormArrayFieldsCount({
                                  ...formArrayFieldsCount,
                                  other_details:
                                    formArrayFieldsCount.other_details + 1,
                                })
                              }}

                              // onClick={other_detailsAddMorebutton}
                            >
                              Add More
                            </button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <Form.Field>
                            <label className="mt-4">Other Remarks</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter a Message"
                              {...register("comments", {})}
                            ></textarea>
                          </Form.Field>
                        </div>
                        <div className="col-md-12 text-center">
                          <Button
                            className="button mt-4"
                            disabled={nomination_finish}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      ) : (
        <div className="h3 container py-5 my-5">
          Thank you for your interest in the Indian ESG Awards. We regret to
          inform you that the nomination deadline has passed and we are no
          longer accepting nominations. <br />
          <br />
          We appreciate your effort in submitting a nomination and encourage you
          to try again next year.
        </div>
      )}
    </>
  )
}
